import React from "react";
import Box from "./Box";


function App() {
  return (
    <div style={{display: "flex", justifyContent: "center", alignItems: "center",}}>
    <Box/>
    </div>

  )
  }
export default App;